@import url('https://fonts.googleapis.com/css2?family=Besley:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&family=Hind+Siliguri:wght@300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@400;500;600;700;800;900&family=Outfit&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Roboto:wght@500&display=swap');

body {
  font-family: sans-serif;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
  /* background: url("../public/image/bg.jpg"); */
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

@font-face {
  font-family: 'OriginTech';
  src: url('../public/origin_tech/OriginTech\ personal\ use.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --bs-light-rgb: 225, 225, 225;
  --border-size: calc(var(--border, 2) * 1px);
  --spotlight-size: calc(var(--size, 150) * 1px);
  -backdrop: hsla(0, 0%, 60%, 0.12);
  --radius: 23;
  --border: 3;
  --backup-border: var(--backdrop);
  --size: 200;
  --spread: 10;
  --outer: 1;
  --base: 120;
}



.container {
  width: 100% !important;
  max-width: 100% !important;
}

.p {
  padding: 0px 90px;
}

.header {
  /* position: fixed; */
  /* left: 0;
  top: 0;
  width: 100%; */
  z-index: 5;
  background-color: #000102;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  /* height: 100px; */
  /* padding: 10px 90px; */
}

#hover-effect {
  position: relative;
  background-color: #2c3e50;
  /* Default background color */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.glow-container[data-glow="true"]:hover {
  box-shadow: 0 0 25px rgba(0, 255, 255, 0.342),
    0 0 35px rgba(0, 255, 255, 0.217);
  transition: box-shadow 0.3s ease-in-out;
}

.menu {
  display: flex;
  position: relative;
  right: 115px;
}

.menu a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "Source Sans 3", sans-serif;
  font-size: 18px;
  margin: 0 20px;
}

.g-box:before {
  background-image: radial-gradient(calc(var(--spotlight-size) * 0.75) calc(var(--spotlight-size) * 0.75) at calc(var(--x, 0) * 1px) calc(var(--y, 0) * 1px), hsl(159, 74%, 80%), transparent 100%);
  filter: brightness(2);
}

.g-box:before,
.g-box::after {
  pointer-events: none;
  content: "";
  position: absolute;
  inset: calc(var(--border-size) * -1);
  border: var(--border-size) solid transparent;
  border-radius: calc(var(--radius) * 1px);
  background-repeat: no-repeat;
  mask: linear-gradient(transparent, transparent),
    linear-gradient(#ffffff, #ffffff);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  background-size: calc(100% + (2 * var(--border-size))) calc(100% + (2 * var(--border-size)));
  background-position: 50% 50%;
  background-attachment: fixed;
}

.presale-btn>button {
  background-color: #f03c3ce6;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  letter-spacing: 0.5px;
  line-height: 18px;
}

.presale-btn>button:hover {
  color: white;
}

.presale {
  position: relative;
  background-size: cover;
  /* background: url("../public/image/home1.jpg"); */
}

.bg-blur {
  content: "";
  position: absolute;
  left: 60px;
  border-radius: 50%;
  background-image: linear-gradient(#4a228e, #f03c3c);
  filter: blur(110px);
  width: 250px;
  height: 250px;
}

.banner-title {
  margin-bottom: 20px;
  background: -webkit-linear-gradient(#fcfeff, #7c7e89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.3rem;
  font-weight: 600;
  line-height: 66px;
}

.banner-title2 {
  margin-bottom: 20px;
  background: -webkit-linear-gradient(#fcfeff, #7c7e89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7rem;
  font-weight: 600;
  /* line-height: 66px; */
}

.sub-heading {
  margin-bottom: 20px;
  color: rgb(198, 198, 198);
  font-size: 1.3rem;
  font-weight: 500;
  /* line-height: 34px; */
  /* margin-top: 40px; */
  text-align: justify;
}

.wallet-box {
  width: 100%;
  max-width: 450px;
  z-index: 1;
  margin-left: auto;
  position: relative;
  border-radius: 20px;
  /* background-image: linear-gradient(#19181d, #1d172a); */
  background-color: #05080d10;
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px);
  padding: 24px;
  /* border: 1px solid white; */
}

.wallet-box h6 {
  font-size: 1.3rem;
  font-weight: 500;
}

.round {
  min-width: 60px;
  padding: 6px 8px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #00000080;
  color: #f03c3c;
}

.indicator {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.value {
  font-size: 26px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 3px;
}

.progress {
  width: 100%;
  border-radius: 6px;
  height: 20px;
  background: #2e2c2ef6;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.bar {
  background-image: linear-gradient(to right, #27e65c, #587bff, #b518ff);
}

.status {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  z-index: 1;
  color: #e1e1e1;
}

.list {
  background-color: white;
  border-radius: 5px;
  ;
}

.list span {
  color: black !important;
  font-weight: 600;

}

/* .dashTitle:before,
.dashTitle:after {
  content: "";
  position: absolute;
  width: 20%;
  height: 2px;
  background: #e1e1e1;
  left: 0;
  top: 50%;
} */

/* .dashTitle:after {
  right: 0;
  left: auto;
} */

.eth-usdt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem !important;
}

.eth-usdt .btn {
  min-width: 100px;
  height: 50px;
  padding: 6px;
  border-radius: 4px;
  color: #fff;
  background: #00000073;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.b1 {
  background: #fff !important;
  color: #000 !important;
}

.b2 {
  display: flex !important;
  flex-direction: column !important;
}

.eth-usdt img {
  height: 28px;
}

.c-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  /* border: 1px solid red; */
  position: relative;
}

.c-btn>div {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  /* border: 1px solid yellow; */
}

.c-btn span {
  font-size: 12px;
}

.inp {
  position: relative;
  padding-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.inp img {
  position: absolute;
  right: 8px;
}

.inp input {
  min-height: 45px;
  padding: 8px 15px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 7px;
  border: 0px;
  color: #e1e1e1;
  background: #00000073;
  line-height: 1;
  border: none;
  outline: none;
  width: 100%;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.connect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.connect .btn,
.connect>.ju367va>.iekbcc0 {
  color: black;
  width: 100%;
  background-color: white;
  text-align: center;
}

.btn1,
.connect>.ju367va>.iekbcc0 {
  background-color: #000000e6 !important;
  color: white !important;
}

.btn {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  padding: 12px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  letter-spacing: 0.5px;
  line-height: 18px;
  width: 100%;
}

.partner {
  display: flex;
  gap: 45px;
  justify-content: center;
  align-items: center;
  /* padding-top: 80px; */
  margin-top: 50px;
}

.partner img {
  height: 50px;
  width: fit-content;
  margin-right: 80px;
}

.insider {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
}

.insider>div {
  position: relative;
  border-radius: 20px;
  /* height: 100%; */
  border: 1px solid;
  border-color: #e1e1e133;
  padding: 40px;
  color: #fff;
  overflow: hidden;
  background-color: #080a0f;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.insider-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
}

.insider-title h3 {
  color: #f03c3c;
  letter-spacing: 5px;
  font-size: 2.3rem;
  font-weight: 600;
  text-transform: uppercase;
}

.m-title {
  font-weight: 500;
  font-size: 1.75rem;
  margin-bottom: 25px;
  line-height: 32px;
}

.m-desc {
  font-weight: 300;
  color: #e1e1e1a9;
  font-size: 1.2rem;
}

.solution {
  position: relative;
}

.sol-title {
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  /* background:black; */

  /* -webkit-text-fill-color: transparent; */
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 40px;
  color: #f03c3c;
}

.solution-card {
  padding: 40px 30px;
  position: relative;
  /* position: relative; */
  border-radius: 20px;
  height: 100%;
  /* border: 1px solid; */
  /* border-color: #e1e1e133; */
  /* padding: 40px; */
  color: #000000;
  overflow: hidden;
  background-color: transparent;
}

.solution-card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  /* background-image: linear-gradient(#d2a4ff26, #ffffff26); */
  z-index: -1;
}

.data {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #f03c3c !important;
}

.learn {
  color: #f03c3c;
  font-size: 1.5rem;
}

.icon::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #0f1bff;
  border-radius: 50%;
  width: 116px;
  height: 116px;
  z-index: -1;
  filter: blur(70px);
}

.icon>img {
  width: 100%;
  height: 240px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.designation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}

.designation .name {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  background: -webkit-linear-gradient(#ffffff, #7c7e89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-size: 600;
}

.designation .des {
  color: #f03c3c;
  font-size: 600;
  font-size: 1.3rem;
  text-align: center;
}

.g-title {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  background: -webkit-linear-gradient(#ffffff, #7c7e89);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42px;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.g-p {
  color: #e7eaee;
  font-size: 1.45rem;
  margin-bottom: 40px;
}

.eco {
  margin: 0px;
  padding: 0px;
}

.eco>div>div {
  padding: 40px 30px;
  position: relative;
  border-radius: 20px;
  height: 100%;
  border: 1px solid;
  border-color: #e1e1e133;
  padding: 40px;
  color: #fff;
  overflow: hidden;
  background-color: #202937;
  margin: 0px;
}

.become {
  color: white;
  font-size: 2.2rem;
  margin-bottom: 0px !important;
}

.contributor {
  color: #f03c3c;
  font-size: 2rem;
  font-weight: 500;
}

.side-img {
  position: absolute;
  top: 0px;
  left: -70px;
  z-index: -1;
}



.star {
  font-size: 1.12rem;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  color: #a09ea1ea;
  line-height: 30px;
}

.span-b {
  background-color: #f03c3c;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.span-b:hover {
  color: white !important;
}


.apply {
  color: #f03c3c;
  font-size: 25px;
}

.ecosystem {
  color: #f03c3c;
  font-size: 28px;
  font-weight: 500;
}

/* .col-lg-4{
  padding: 0px;
} */
.g-card {
  position: relative;
  border-radius: 20px;
  height: 100%;
  /* border: 1px solid;
  border-color: #12ed49e8; */
  /* padding: 20px !important; */
  color: #fff;
  overflow: hidden;
  background-color: #020a18;
  margin: 0px;
  padding: 5px 30px;
}

.number {
  font-weight: 900;
  color: #fff;
  line-height: 0.95;
  background: linear-gradient(#d2a4ff26, #ffffff26);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 95px;
}

.g-button {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  border-radius: 8px;
  padding: 12px 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  letter-spacing: 0.5px;
  line-height: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f03c3c;

  margin: 20px auto !important;
}

.g-button:hover {
  color: white !important;
}

.footer a {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-decoration: none;
  color: #7c7e89;
}

.footer a:hover {
  color: #f03c3c;
  font-weight: 600;
}

.bottom-footer {
  border-top: 1px solid #ffffff;
  padding-top: 55px;
}

.team img {
  height: 400px !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  background-color: #1d002e !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 20px 4px #050505, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  background-color: #090b0e !important;
  color: white !important;
}

.vertical-timeline-element-content {
  box-shadow: none !important;
  --webkit-box-shadow: none !important;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #3e6ccc !important;
  /* border: 7px solid #3e6ccc !important; */
}

.tokenomics div,
.tokenomics span {
  text-transform: uppercase;
  color: white;
}

.tokenomics div {
  margin-bottom: 20px;
  padding-right: 20px;
  /* width: 100px; */
  display: flex;
  gap: 5px;
}

.tokenomics span {
  width: fit-content;
  display: flex;
  font-size: 32px;
  /* color: #202937; */
  font-weight: 600;
}

.left-s {
  color: #7c7e89 !important;
  font-weight: 600;
}

.navbar {
  display: none;
}

.d-table {
  background-color: black;
  color: white;
  border-radius: 5px solid white !important;
}

th {
  color: #00e15c;
}

.acc {
  font-family: sans-serif;
}

.row {
  margin: 0px;
}

.contactUs {
  border-radius: 5px;
  font-family: sans-serif;
  height: fit-content;
  background-color: #020a18;
}

.con {
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  /* background-color: #1907226e; */
  align-items: center;
}

.contactUs {
  background-color: #05080d;

}

.contactUs .col-lg-12 {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  color: white;
}

.contactUs input,
.contactUs textarea {
  min-height: 45px;
  padding: 8px 15px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 7px;
  border: 0px;
  color: #e1e1e1;
  background: #00000073;
  line-height: 1;
  border: none;
  outline: none;
  width: 100%;
  margin-top: 15px;

}

.contactUs button {
  margin-top: 30px;
  background-color: #f03c3c;
  color: white;
  border: none;
  outline: none;
  padding: 10px 0px;
  border-radius: 5px;
  font-size: 20px;
  font-family: originTech;
}

.contactUs h1 {
  font-size: 30px !important;
  text-align: center;
  color: #f03c3c !important;
  font-family: originTech;

}

.address {
  font-size: 30px;
  color: #ffffff !important;
  font-family: originTech;
  border: 1px solid #e1e1e133;
  border-radius: 20px;
}



@media (max-width: 450px) {

  .r {
    margin-top: 115px !important;
  }

  .menu {
    /* display: none;
     */
    color: white;
    /* padding-left: 10px; */
    right: 0px !important;
  }

  ul {
    padding-left: 0px !important;
  }

  .header {
    padding: 0px;
  }

  .p {
    padding: 0px;
  }

  .team .col-lg-4>div {
    width: 100%;
  }

  .team img {
    width: 100%;
  }

  .insider {
    flex-direction: column;
  }

  /* body{
    padding: 10px;
  } */
  .banner-title {
    font-size: 30px;
    line-height: 36px;
  }

  .sub-heading {
    font-size: 18px;
    line-height: 24px;
  }

  .container {
    /* padding: 10px !important; */
  }

  .presale .presale-btn {
    width: 280px;
    margin: auto;
  }

  .presale .presale-btn .btn {
    width: 280px !important;
  }

  .timer {
    margin: auto;
  }

  .wallet-box {
    padding: 10px;
  }

  .c-btn>div {
    width: 100%;
  }

  .c-btn {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .inp input {
    width: 100%;
  }

  .insider {
    margin-top: 60px;
  }

  .insider div {
    padding: 10px;
  }

  .solution-card {
    padding: 10px;
  }

  .solution .col-lg-4 {
    margin-bottom: 20px;
  }

  .team .col-lg-4 {
    justify-content: center;
    margin: auto;
  }

  .eco>div>div {
    padding: 15px;
  }

  .eco>div {
    margin-bottom: 30px;
  }

  .g-card {
    padding: 10px;
    /* margin-bottom: 20px; */
  }

  .system>div {
    margin-bottom: 15px;
  }

  .g-card {
    padding: 10px !important;
  }

  .logo {
    position: relative;
    top: -13px;
  }

  .presale-btn {}

  .header {
    justify-content: space-around;
  }

  .row {
    margin: 0px;
    width: 100% !important;
    padding: 0px;
  }

  .acc>div {
    margin-bottom: 30px;
    padding: 0px;
  }

  .tokenomics span {
    font-size: 20px;
  }

  .t img {
    margin-top: 80px !important;
  }

  .header {
    display: none;
  }

  .navbar {
    display: block;
    background-color: #000000 !important;
  }

  .list>div {
    width: fit-content;
  }

  #service {
    margin-top: 160px !important;
  }

  #service .sub-heading {
    padding-left: 10px;
    padding-right: 5px;
  }

  .sol-title {
    margin-top: 90px !important;
  }

  .footer {
    margin-top: 10px !important;
  }

  .add {
    font-size: 12px !important;

  }

  .add1 {
    font-size: 20px !important;
    padding-left: 0px !important;
  }
}

/* color tracking  */

@media (min-width: 650px) {
  .setH {
    height: 550px;
  }


}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%; */
  /* height: 100%; */
  /* overflow: hidden; */
  /* z-index: -1; */
  /* Ensures the video stays behind other content */
}

.video-background video {
  min-width: 100%;
  min-height: 100%;

}

.video-background {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* Prevent overflow if video is larger */
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the video covers the area without stretching */
  transform: translate(-50%, -50%);
  z-index: -1;
  /* Push video to the background */
}

.content {
  position: relative;
  z-index: 1;
  /* Bring content to the front */
  color: white;
  text-align: center;
  padding-top: 20vh;
  /* Adjust padding to position content */
}